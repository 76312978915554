<template>
    <el-checkbox v-bind="$attrs" v-on="$listeners" true-label="30" false-label='' v-if="parseInt(this.$attrs.repayType) !== 0">已还款</el-checkbox>
</template>

<script>
export default {
  name: 'RepaymentStatus',
  data () {
    return {
      checked: false
    }
  }
}
</script>

<style scoped></style>
