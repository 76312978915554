import UploadFile from '../components/repayment-upload'
import RepaymentStatus from '../components/repayment-status'
import { formatDate } from '@/utils/auth/common'
import Amountinput from '@/components/input/amount-input/amount-input'
import { Message } from 'element-ui'

export const columnsConfig = (content) => {
  if (content.form.type === 'L') { // 还款流水
    return [
      {
        label: '期数',
        prop: 'repayPeriods'
      },
      {
        label: '还款时间',
        prop: 'preRepayDate',
        formatter: row => {
          return formatDate(row.preRepayDate, 'YY-MM-DD')
        }
      },
      {
        label: '还款类型',
        prop: 'repayType',
        formatter: row => {
          console.log(row, '1231231')
          return parseInt(row.repayType) === 0 ? '本金' : (parseInt(row.repayType) === 1 ? '服务费' : '本息')
        }
      },
      {
        label: '应还金额(元)',
        prop: 'preRepayAmount',
        'show-overflow-tooltip': true,
        align: 'right',
        formatter: row => {
          return (row.preRepayAmount ? row.preRepayAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
        }
      },
      {
        label: '实收日期',
        prop: 'actualRepayDate',
        formatter: row => {
          return formatDate(row.actualRepayDate, 'YY-MM-DD')
        }
      },
      {
        label: '实收金额(元)',
        prop: 'remainAmount',
        align: 'right',
        'show-overflow-tooltip': true,
        formatter: row => {
          return (row.actualRepayAmount ? row.actualRepayAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
        }
      },
      {
        label: '备注',
        prop: 'memo',
        'show-overflow-tooltip': true
      },
      {
        label: '附件',
        prop: 'fileName',
        'show-overflow-tooltip': true
      },
      {
        label: '操作人',
        prop: 'operator',
        'show-overflow-tooltip': true
      },
      {
        label: '操作时间',
        prop: 'modifyTime'
        // formatter: row => {
        //   return formatDate(row.modifyTime, 'YY-MM-DD')
        // }
      }
    ]
  } else if (content.form.type === 'C') { // 还款登记详情
    return [
      {
        label: '还款时间',
        prop: 'preRepayDate',
        formatter: row => {
          return formatDate(row.preRepayDate, 'YY-MM-DD')
        }
      },
      {
        label: '还款类型',
        prop: 'repayType',
        formatter: row => {
          return parseInt(row.repayType) === 0 ? '本金' : (parseInt(row.repayType) === 1 ? '服务费' : '本息')
        }
      },
      {
        label: '应还金额(元)',
        prop: 'preRepayAmount',
        align: 'right',
        'show-overflow-tooltip': true,
        formatter: row => {
          return (row.preRepayAmount ? row.preRepayAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
        }
      },
      {
        label: '实收日期',
        prop: 'actualRepayDate',
        formatter: row => {
          return formatDate(row.actualRepayDate, 'YY/MM/DD')
        }
      },
      {
        label: '实收金额(元)',
        prop: 'actualRepayAmount',
        align: 'right',
        'show-overflow-tooltip': true,
        formatter: row => {
          return (row.actualRepayAmount ? row.actualRepayAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
        }
      },
      {
        label: '备注',
        prop: 'memo',
        'show-overflow-tooltip': true
      },
      {
        label: '附件',
        prop: 'fileName',
        'show-overflow-tooltip': true
      },
      {
        label: '操作人',
        prop: 'operator',
        formatter: row => {
          return row.operator ? row.operator : '系统'
        }
      },
      {
        label: '操作时间',
        prop: 'modifyTime',
        width: '150'
        // formatter: row => {
        //   return formatDate(row.preRepayDate, 'YY/MM/DD hh:mm:ss')
        // }
      }
    ]
  }
}
// 还款登记弹出框
export const dialogConfig = (conent) => {
  const checkAge = (rule, value, callback) => {
    if (!value) {
      return callback(new Error('请输入实收金额'))
    } else if (value <= 0) {
      return callback(new Error('实收金额必须大于0'))
    } else if (value > conent.queryParas.remainAmount) {
      return callback(new Error('实收金额不能超过剩余未还金额'))
    } else {
      callback()
    }
  }

  const startDatePicker = {
    disabledDate (time) {
      return time.getTime() <= new Date(`${conent.deLay}`)
    }
  }
  return [
    {
      label: '应还金额(元)：',
      prop: 'preRepayAmount',
      rules: [{ required: false, message: '请输入', trigger: 'blur' }],
      span: 24,
      attrs: {
        disabled: true,
        placeholder: '请输入'
      }
    },
    {
      label: '应还日期：',
      prop: 'preRepayDate',
      rules: [{ required: false, message: '请输入', trigger: 'blur' }],
      span: 24,
      attrs: {
        disabled: true,
        placeholder: '请输入'
      }
    },
    {
      label: '实收金额(元)：',
      prop: 'actualRepayAmount',
      tag: Amountinput,
      rules: [{ required: true, validator: checkAge, trigger: 'blur' }],
      span: 24,
      attrs: {
        placeholder: '请输入',
        type: 'Y'
      }
    },
    {
      label: '实收日期：',
      prop: 'actualRepayDate',
      rules: [{ required: true, message: '请选择日期', trigger: 'blur' }],
      tag: 'el-date-picker',
      span: 24,
      attrs: {
        placeholder: '请选择',
        pickerOptions: startDatePicker
      }
    },
    {
      label: '附件：',
      prop: 'fileName',
      span: 24,
      rules: [{ required: false, message: '请上传附件', trigger: 'blur' }],
      tag: UploadFile,
      attrs: {
        fileShow: conent.fileShow
      },
      on: {
        uploadFile: (data) => {
          if (data) {
            Message.success('附件上传成功')
            conent.fileShow = true
            conent.queryParas.fileId = data.keyId
            conent.queryParas.fileName = data.fileName
          }
        }
      }
    },
    {
      label: '备注：',
      prop: 'memo',
      rules: [{ required: false, message: '请输入备注', trigger: 'blur' }],
      span: 24,
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: parseInt(conent.queryParas.repayType) === 0 ? '' : '状态：',
      prop: 'repayStatus',
      span: 24,
      tag: RepaymentStatus,
      rules: [{ required: false, message: '请输选择状态', trigger: 'blur' }],
      attrs: {
        repayType: conent.queryParas.repayType
      }
    }
  ]
}
