<template>
  <div class="dialog">
    <BaseDialog
      :visible.sync="Visible"
      :title="form.title"
      class="feedBackDialog"
      :showFooter="false"
      @close="close"
    >
      <div class="row cont-rl cont-center" v-if="form.type === 'C' || form.type === 'D'">
        <div v-if="form.type === 'C' || form.type === 'D'" >
          还款期数：{{ repayPeriods }}
        </div>
        <div v-if="form.type === 'D'">
          还款类型:{{ parseInt(queryParas.repayType) === 0 ? '本金' : (parseInt(queryParas.repayType) === 1?'服务费':'本息') }}
        </div>
      </div>

      <Basetable
        :columns="columns"
        :showPage="false"
        :tableAttrs="{
          data: settableData,
          stripe: true,
        }"
        :type="form.type"
        ref="tableData"
        :webPage="true"
        :selfChangeHeight="160"
        v-if="form.type !== 'D'"
      >
        <template slot="fileName" slot-scope="scope">
          <a href="#" @click="fileView(scope.row)" style="color:#2862E7">{{ scope.row.fileName }}</a>
        </template>
      </Basetable>
      <BaseForm
        :componentList="dialogConfig"
        :showBtns="false"
        :formAttrs="{
          model: queryParas,
          labelWidth: '150px',
          'label-position': 'right',
        }"
        class="formStyle"
        ref="Formdata"
        v-if="form.type === 'D'"
      >
      </BaseForm>
      <div
        slot="footer"
        style="text-align: center; margin-top: 20px"
        v-if="form.type === 'D'"
      >
        <div slot="footer" style="text-align: center; margin-top: 20px" class="footer">
          <BaseButton type="primary" label='提交' @click.prevent="saveRepayregistInfo"></BaseButton>
          <BaseButton type="default" label='取消' @click="close"></BaseButton>
        </div>
      </div>

      <!--       提交确认-->
      <div v-if="coreVisible">
        <BaseDialog
          :visible.sync="coreVisible"
          title="提示"
          class="feedBackDialog"
          :showFooter="false"
          :show-close="false"
          @close="close"
        >
          <div class="content">
            <repayment-comfirm :bank-account-info="finRepayBankAccountInfo" :queryParas="queryParas"></repayment-comfirm>
          </div>

          <div slot="footer" style="text-align: center; margin-top: 20px" class="footer">
            <BaseButton type="primary" label='提交' @click.prevent="commitData"></BaseButton>
            <BaseButton type="default" label='取消' @click.prevent="coreVisible = false"></BaseButton>
          </div>
        </BaseDialog>
      </div>

      <div v-if="form.type !== 'D'" slot="footer" style="text-align: center; margin-top: 20px" class="footer">
        <BaseButton type="default" label='关闭' @click.prevent="close"></BaseButton>
      </div>
    </BaseDialog>

    <!-- 文件预览 -->
    <pre-view :count="count" :fileId="fileId" :isOpen="true" :fileType="fileType" ref='preView' />
  </div>
</template>
<script>

import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import Basetable from '@/components/common/table/base-table/base-table'
import BaseForm from '@/components/common/base-form//base-form'
import PreView from '@/components/pre-view/pre-view.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import RepaymentComfirm from './repayment-comfirm'
import { columnsConfig, dialogConfig } from '../utils/repayment-registration.config'
import { repayregistApi } from '@/api/businessApi'
import { formatDate } from '@/utils/auth/common'

export default {
  components: {
    Basetable,
    BaseDialog,
    BaseForm,
    BaseButton,
    PreView,
    RepaymentComfirm
  },
  props: {
    // 登记的数据
    form: {
      type: Object,
      default: () => {
      }
    },
    // 控制对话框的显示
    Visible: {
      type: Boolean,
      default: false
    },
    // 提交数据
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      queryParas: {
        actualRepayDate: new Date().getTime()
      },
      fileShow: false, // 清除附件名
      visibleView: false,
      coreVisible: false,
      count: 1,
      fileId: '',
      fileType: '',
      deLay: '',
      finRepayBankAccountInfo: []
    }
  },

  watch: {
    Visible: function (val) {
      if (this.form.data && val) {
        this.queryParas = {}
        this.deLay = this.form.delay
        this.queryParas.actualRepayDate = new Date().getTime()
        this.queryParas = Object.assign({}, this.queryParas, this.form.data)
        this.queryParas.preRepayDate = this.form.data.preRepayDate < 0 ? formatDate(new Date().getTime(), 'YY-MM-DD') : formatDate(this.form.data.preRepayDate, 'YY-MM-DD')
        this.queryParas.repayType = this.queryParas.repayType.toString()
        this.queryParas.operator = this.$store.state.user.userInfo.nickName
        this.queryParas.actualRepayAmount = this.form.data.remainAmount === '--' ? 0 : this.form.data.remainAmount
      }
    }
  },
  computed: {
    dialogConfig () {
      return dialogConfig(this)
    },
    columns () {
      return columnsConfig(this)
    },
    // 期数
    repayPeriods () {
      if (this.form.type === 'C') {
        return this.tableData.length > 0 ? this.tableData[0].repayPeriods : 0
      } else {
        return this.form.data.repayPeriods
      }
    },
    // 排序
    settableData () {
      const list = this.tableData
      return list.sort((a, b) => {
        return a.repayPeriods - b.repayPeriods
      })
    }
  },

  methods: {

    /***
     * 关闭对话框
     */
    close () {
      this.fileShow = false
      this.$emit('update:visible', false)
      if (this.form.type === 'D') {
        this.$refs.Formdata.resetFields()
      }
    },
    /***
     * 文件预览
     * @param row
     */
    fileView (row) {
      this.visibleView = true
      if (row && row.fileId && row.fileName) {
        const FileExt = row.fileName.replace(/.+\./, '')
        this.fileId = row.fileId
        this.fileType = FileExt
        this.visibleView = true
        this.count++
      }
    },

    /***
     * 保存还款登记
     */
    saveRepayregistInfo () {
      this.$refs.Formdata.validate((valid) => {
        if (valid) {
          this.coreVisible = true
          if (this.form.account) {
            this.finRepayBankAccountInfo = this.form.account
          }
        }
      })
    },

    /***
     * 提交
     */
    commitData () {
      repayregistApi.saveRepayregistInfo(this.form.data.keyId, this.queryParas)
        .then(res => {
          this.Visible = false
          this.fileShow = false
          this.coreVisible = false
          this.$parent.getRepayregistDetail()
          this.queryParas = {}
          this.$message.success('提交成功')
        })
        .catch(res => {
          this.$message.error('提交失败')
        })
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-dialog__footer {

  background: #F5F5F5;
}

.feedBackDialog  {
  /deep/ .el-dialog {
    height: 70% !important;
  }
}
.footer {
  background: #F5F5F5;
}

.bottombox {
  > div {
    display: inline-block;
    width: 50%;
  }
}

.row {
  display: flex;
}

.cont-center {
  align-items: center;
}

.cont-rl {
  justify-content: space-between;
  width: 40%;
  margin: 20px 10px;
}

.redbox {
  width: 10px;
  height: 10px;
  background: #2862E7;
  margin-right: 8px;
}
</style>
