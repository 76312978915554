var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dialog"},[_c('BaseDialog',{staticClass:"feedBackDialog",attrs:{"visible":_vm.Visible,"title":_vm.form.title,"showFooter":false},on:{"update:visible":function($event){_vm.Visible=$event},"close":_vm.close}},[(_vm.form.type === 'C' || _vm.form.type === 'D')?_c('div',{staticClass:"row cont-rl cont-center"},[(_vm.form.type === 'C' || _vm.form.type === 'D')?_c('div',[_vm._v(" 还款期数："+_vm._s(_vm.repayPeriods)+" ")]):_vm._e(),(_vm.form.type === 'D')?_c('div',[_vm._v(" 还款类型:"+_vm._s(parseInt(_vm.queryParas.repayType) === 0 ? '本金' : (parseInt(_vm.queryParas.repayType) === 1?'服务费':'本息'))+" ")]):_vm._e()]):_vm._e(),(_vm.form.type !== 'D')?_c('Basetable',{ref:"tableData",attrs:{"columns":_vm.columns,"showPage":false,"tableAttrs":{
        data: _vm.settableData,
        stripe: true,
      },"type":_vm.form.type,"webPage":true,"selfChangeHeight":160},scopedSlots:_vm._u([{key:"fileName",fn:function(scope){return [_c('a',{staticStyle:{"color":"#2862E7"},attrs:{"href":"#"},on:{"click":function($event){return _vm.fileView(scope.row)}}},[_vm._v(_vm._s(scope.row.fileName))])]}}],null,false,939238071)}):_vm._e(),(_vm.form.type === 'D')?_c('BaseForm',{ref:"Formdata",staticClass:"formStyle",attrs:{"componentList":_vm.dialogConfig,"showBtns":false,"formAttrs":{
        model: _vm.queryParas,
        labelWidth: '150px',
        'label-position': 'right',
      }}}):_vm._e(),(_vm.form.type === 'D')?_c('div',{staticStyle:{"text-align":"center","margin-top":"20px"},attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"footer",staticStyle:{"text-align":"center","margin-top":"20px"},attrs:{"slot":"footer"},slot:"footer"},[_c('BaseButton',{attrs:{"type":"primary","label":"提交"},on:{"click":function($event){$event.preventDefault();return _vm.saveRepayregistInfo.apply(null, arguments)}}}),_c('BaseButton',{attrs:{"type":"default","label":"取消"},on:{"click":_vm.close}})],1)]):_vm._e(),(_vm.coreVisible)?_c('div',[_c('BaseDialog',{staticClass:"feedBackDialog",attrs:{"visible":_vm.coreVisible,"title":"提示","showFooter":false,"show-close":false},on:{"update:visible":function($event){_vm.coreVisible=$event},"close":_vm.close}},[_c('div',{staticClass:"content"},[_c('repayment-comfirm',{attrs:{"bank-account-info":_vm.finRepayBankAccountInfo,"queryParas":_vm.queryParas}})],1),_c('div',{staticClass:"footer",staticStyle:{"text-align":"center","margin-top":"20px"},attrs:{"slot":"footer"},slot:"footer"},[_c('BaseButton',{attrs:{"type":"primary","label":"提交"},on:{"click":function($event){$event.preventDefault();return _vm.commitData.apply(null, arguments)}}}),_c('BaseButton',{attrs:{"type":"default","label":"取消"},on:{"click":function($event){$event.preventDefault();_vm.coreVisible = false}}})],1)])],1):_vm._e(),(_vm.form.type !== 'D')?_c('div',{staticClass:"footer",staticStyle:{"text-align":"center","margin-top":"20px"},attrs:{"slot":"footer"},slot:"footer"},[_c('BaseButton',{attrs:{"type":"default","label":"关闭"},on:{"click":function($event){$event.preventDefault();return _vm.close.apply(null, arguments)}}})],1):_vm._e()],1),_c('pre-view',{ref:"preView",attrs:{"count":_vm.count,"fileId":_vm.fileId,"isOpen":true,"fileType":_vm.fileType}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }